import axios from 'axios'
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK, VUE_APP_SSO_LINK, VUE_APP_INSURANCE_LINK, VUE_APP_TRANSPORTATION_LINK, VUE_APP_WEBSITE_LINK, VUE_APP_VETERINARY_LINK, VUE_APP_SHIPPING_API, VUE_APP_SHIPPING_API_SECRET, VUE_APP_SHIPPING_API_KEY } from './config/constant'

export const apiHeader = () => {
  return {
    Authorization: localStorage.getItem('userToken') ? `Bearer ${JSON.parse(localStorage.getItem('userToken'))}` : null,
    Accept: 'application/json',
    ContentType: 'application/json',
    'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar',
    'Content-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar'
  }
}
export const apiResponseCatch = (error) => {
  if (error?.response?.data?.errors && Object.keys(error.response.data.errors).length) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.data.errors?.hasOwnProperty('translations')) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors.translations)) {
        core.showSnackbar('error', value)
      }
    } else {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
  } else if (error?.response?.data) {
    core.showSnackbar('error', error.response.data.message)
  }
  return Promise.reject(error)
}

export function singleSignOn () {
  const apiClient = axios.create({
    baseURL: VUE_APP_SSO_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function marketPlace () {
  const apiClient = axios.create({
    baseURL: VUE_APP_MARKETPLACE_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function insurance () {
  const apiClient = axios.create({
    baseURL: VUE_APP_INSURANCE_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function joining () {
  const apiClient = axios.create({
    baseURL: VUE_APP_MARKETPLACE_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function website () {
  const apiClient = axios.create({
    baseURL: VUE_APP_WEBSITE_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function transportation () {
  const apiClient = axios.create({
    baseURL: VUE_APP_TRANSPORTATION_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function veterinary () {
  const apiClient = axios.create({
    baseURL: VUE_APP_VETERINARY_LINK,
    headers: apiHeader()
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}

export function shipping () {
  const apiClient = axios.create({
    baseURL: VUE_APP_SHIPPING_API,
    headers: {
      'SHIPPING-X-API-KEY': VUE_APP_SHIPPING_API_KEY,
      'SHIPPING-X-API-SECRET': VUE_APP_SHIPPING_API_SECRET
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
