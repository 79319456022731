/* eslint-disable*/ 
export default {
  home: 'الصفحة الرئيسية',
  mainPage: 'الرئيسية',
  aboutUs: 'معلومات عنا',
  services: 'خدماتنا',
  mediaCenter: 'المركز الإعلامى',
  images: 'صور',
  videos: 'فيديوهات',
  contactUs: 'تواصل معنا',
  shops: 'المتاجر',
  permissions: 'التصاريح',
  permissionsInfo: 'قريبا معنا ،ستتمكن من الحصول على التصاريح الخاصة بالإبل بسهولة من خلال منصتنا',
  insurance: 'التأمين',
  insuranceInfo: 'الآن بإمكانك التأمين على نقل إبلك واستلام وثيقة التأمين',
  transport: 'النقل',
  transportInfo: 'الان بإمكانك نقل الإبل الخاصة بك داخل المملكة',
  veterinarian: 'البيطرة',
  veterinaryInfo: 'نوفر لك أطباء معتمدين لراحة الإبل الخاصة بك',
  camelTrade: 'تداول الأبل',
  events: 'الفعاليات',
  statistics: 'إحصائيات',
  InsurancePolicies: 'وثيقة تأمين',
  purchases: 'عمليات شراء',
  transportationsProcess: 'عمليات النقل',
  merchants: 'عدد التجار',
  productsCount: 'عدد المنتجات',
  doctorsCount: 'عدد الأطباء',
  searchInAllDepartments: 'بحث في جميع الأقسام',
  footer: 'منصة إبل هي منصة متخصصة بتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح، ومتاجر الأعلاف وأعذية ومستلزمات الإبل وخدمات البيطرة، كما تعتبر منصة تداول الإبل الرئيسية من بيع وشراء وتأجير ومشاركة',
  termsConditions: 'الشروط والسياسات',
  seeAll: 'مشاهدة الكل',
  IdNumber: 'رقم الهوية',
  password: 'الرقم السري',
  phoneNumber: 'رقم الجوال',
  userName: 'اسم المستخدم',
  fullName: 'الاسم بالكامل',
  email: 'البريد الالكترونى',
  otbCode: 'الرمز',
  storeType: 'نوع المتجر',
  storeNameInArabic: 'اسم المتجر بالعربية',
  storeNameInEnglish: 'اسم المتجر بالإنجليزية',
  theCity:'المدينة',
  storeAddress: 'عنوان المتجر',
  commissionMessage: "يرجى العلم أنه سيتم إضافة نسبة {percentage}% على قيمة كل طلب.",
  news: 'الأخبار',
  aboutUsInfo: 'منصة إبل هي أول منصة تقدم سوق تجاري متكامل لكافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح، ومتاجر الأعلاف و الأغذية ومستلزمات الإبل وخدمات البيطرة، بالإضافة إلى تداول وتأجير ومشاركة الإبل. حيث يمكنك الاستمتاع  بالتسوق في العديد من المتاجر والاستفادة بجميع الخدمات المقدمة من مختلف التجار، الأطباء وأصحاب المتاجر اللذين يعرضون خدماتهم على منصة إبل',
  dontHaveAccount: 'ليس لديك حساب ؟ ',
  eventAddress: 'عنوان الحدث',
  eventDescription: 'وصف للحدث هذا نص عشوائي',
  userProfile: 'الملف الشخصى',
  continue: 'التالى',
  back: 'الرجوع',
  rs: 'رس',
  termsCondition: 'الأحكام والشروط',
  privacyPolicy: 'سياسة الخصوصية',
  reserved: '©جميع الحقوق محفوظة لمنصة إبل 2022',
  ourServices: 'خدماتنا',
  more: 'المزيد',
  photoGallery: 'معرض الصور',
  videoGallery: 'معرض الفديو'
}
