export default {
  insurance: 'التأمين',
  completeInsuranceData: 'هل تريد تكملة بيانات التأمين؟',
  insureNow: 'أمن الآن',
  termsAndDetails: 'قراءة الشروط والتفاصيل',
  insureDetails: 'أطلق بيت التأمين بالشراكة مع نادي الإبل أول وثيقة تأمين لنقل الإبل في المملكة، نظرً ا لما تمثله الإبل من أهمية في تاريخنا، ولارتباطها بتراثنا وثقافتنا، ولأهمية رعاية الإبل، وجبت علينا المبادرة بإطلاق وثيقة تأمين نقل الإبل. حيث لم تتأخر المملكة منذ عهد المؤسس الملك عبدالعزيز بن عبدالرحمن (طيب الله ثراه) عن دعم الموروث العربي، الذي يتميز به سكان الجزيرة العربية',
  firstInsure: 'أول تأمين لنقل الإبل في المملكة',
  nowYouCanInsure: 'الآن بإمكانك التأمين على نقل إبلك واستلام وثيقة التأمين',
  InsuranceTargetAudience: 'المستهدفين في التامين',
  InsuranceTargetAudienceInfo: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع.',
  CamelTransportInsurance: 'تأمين نقل الإبل',
  CamelTransportInsuranceInfo: 'تستطيع الحصول على تأمين لنقل الإبل الخاصة بك داخل المملكة العربية السعودية من خلال تعبئة الوثيقة المتوفرة في الموقع والحرص على ملئ جميع الطلبات',
  CamelTransportInsuranceDesc: 'بعد الانتهاء من تعبئة النموذج سيظهر لك سعر وثيقة نقل الإبل وفي حال قبولك للسعر يتم تحويلك لنظام الدفع من خلال احدى وسائل الدفع المعتمدة بالسعودية ،وبعدها تصدر فاتورة استلام الطلب ومن ثم وثيقة تأمين النقل.',
  ChooseTheRightInsuranceForYou: 'اختر التأمين المناسب لك',
  ChooseTheRightInsuranceForYouInfo: 'الآن يمكنك اختيار شركة التأمين المناسبة للتأمين على إبلك بالسعر المناسب لك مع مجموعة من افضل شركات التأمين في المملكة العربية السعودية',
  howToInsure: 'كيف تؤمن؟',
  howToInsureInfo: 'إذا قررت تنقل إبل من مكان إلى آخر داخل المملكة اشترك من خلال أيقونة “أمن الآن” ومن ثم قم بتعبئة النموذج مع مراعاة تعبئة جميع الطلبات في الحقول وبذلك تكون قدمت طلب وثيقة تأمين نقل الإبل المقدمة من خلال بيت التأمين IHC',
  howToPay: 'كيف أدفع؟',
  howToPayInfo: 'يمكنك الدفع بسهولة لتأمين نقل الإبل الخاص بك من خلال طرق الدفع المعتمدة في المملكة العربية السعودية المتمثلة في المحفظة الإلكترونية أو عن طريق البطاقات الإئتمانية المتمثلة في مدى و باي موب',
  insureName: 'اسم طالب التأمين',
  chipSerialNumber: 'الشريحة / الرقم التسلسلي',
  camelValue: 'قيمة المتن',
  status: 'الحالة',
  chipNumber: 'رقم الشريحة',
  chipNumberExists: 'رقم الشريحة موجود',
  chipNumberNotExists: 'رقم الشريحة غير موجود',
  chipNumberDuplicated: 'رقم الشريحة متكرر',
  delete: 'حذف',
  save: 'حفــظ',
  addCamel: 'إضافة متن',
  totalValueInsured: 'إجمالي قيمة المتن / المتون المؤمنة',
  camelTotalValue: 'إجمالي قيمة التأمين',
  insuranceCompany: 'شركة التأمين',
  ChooseInsuranceCompany: 'قم باختيار شركة التامين',
  amountInsured: 'المبلغ المؤمن عليه',
  CertificationFees: 'رسوم الشهادة',
  coverage: 'التغطية',
  insuranceHolder: 'اسم صاحب التأمين',
  transportationInsuranceCoverage: 'تغطية تأمين النقل البري',
  includesAllExpenses: 'هذا يشمل جميع المصاريف',
  valueTax: 'ضريبة القيمة المضافة',
  termsAndConditions: 'الشروط والأحكام',
  customerData: 'بيانات العميل',
  quotations: 'التسعيرات',
  shipmentInformation: 'معلومات الشحنة',
  payments: 'الدفعات',
  paymentCompleted: 'تم الدفع بنجاح',
  thanks: 'شكراً لك ،',
  transferCamel: 'هل تود القيام بنقل الإبل؟',
  clickHere: 'اضغط هنا',
  insureCompanyInfo: 'بيانات شركة التأمين',
  total: 'الإجمالي',
  newStart: 'البدأ من جديد',
  dataComplement: 'تكملة البيانات',
  insurancePolicy: 'بوليصة التأمين',
  policyNumber: 'رقم البوليصة',
  receipt: 'الإيصال',
  gotToProfile: 'الذهاب إلي الملف الشخصي',
  pricingDetails: 'تفاصيل التسعيرة',
  insuredLoss: 'من المبلغ المؤمن عليه وكل خسارة تقع لأي متن',
  enduranceValue: 'قيمة التحمل',
  insuredAccount: 'رقم حساب المؤمن له(IBAN)',
  agreeAllInsuranceData: 'أقر بموجبه بأن جميع البيانات في طلب التأمين هذا صحيحة وأن المستندات المرفقة هي مستندات صالحة.',
  agreeToAll: 'أوافق بموجب هذا على جميع',
  idHolder: 'معرف حامل الوثيقة',
  discountQuantity: 'كمية الخصم',
  rs: 'ر.س',
  transportationCoverage: 'تغطية تأمين النقل البري',
  choose: 'اختر',
  previous: 'السابق',
  continue: 'التالى',
  from: 'من',
  to: 'إلى',
  bookingDate: 'تاريخ الحجز',
  insurancePeriod: 'فترة التأمين'
}
