export default {
  ibbilMarketPlaces: 'متاجر منصة إبل',
  categories: 'الاقسام',
  stores: 'المتاجر',
  products: 'المنتجات',
  LatestStores: 'أحدث المتاجر',
  store: 'المتجر',
  aboutStores: 'عن المتاجر',
  aboutStore: 'عن المتجر',
  allStores: 'كل المتاجر',
  camelStores: 'متاجر الإبل',
  feedStores: 'متاجر الإعلاف',
  camelProductsStores: 'متاجر منتجات الإبل',
  animalFoodStores: 'متاجر الأغذية الحيوانية',
  camelSuppliesStores: 'متاجر مستلزمات الإبل',
  electronicChipStores: 'متاجر الشريحة الإلكترونية',
  driveTo: 'التوصيل ل',
  TermsAndConditions: 'الشروط والأساسيات',
  seeMore: 'عرض المزيد',
  addToCart: 'إضافة إلى السلة',
  inCart: 'يوجد فى السلة',
  addToFavourite: 'إضافة إلى المفضلة',
  backToProfile: 'الرجوع',
  quantity: 'الكمية',
  availableQuantity: 'الكمية المتوفرة',
  savedPrice: 'وفرت',
  beforePrice: 'قبل',
  discount: 'خصم',
  discountCode: 'كود الخصم',
  price: 'السعر',
  priceNow: 'السعر الأن',
  total: 'الاجمالى',
  Specifications: 'المواصفات',
  clientsComments: 'تقييمات العملاء',
  seeAll: 'مشاهدة الكل',
  mayYouLike: 'قد يعجبك أيضا',
  all: 'الكل',
  validProductRate: 'تقييم موثق للمنتج',
  userProductComments: 'تعليقات المشتريين للمنتج',
  validProductPayment: 'عملية شراء موثقة',
  commentedAt: 'تم التعليق فى',
  saveToFavourite: 'اضافة للمفضلة',
  removeFromFavourite: 'ازالة من للمفضلة',
  backToStore: 'عودة للمتجر',
  continuePayment: 'متابعة الشراء',
  mainQuestions: 'الأسئلة الشائعة',
  marketplaceInfo: 'منصة إبل تقدم سوق تجاري متكامل لتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل هذه الخدمات متاجر الأعلاف والأغذية ومستلزمات الإبل ، حيث تتوفر على منصة إبل مجموعة كبيرة ومتنوعة من المتاجر الخاصة بمختلف إحتياجات ومستلزمات الإبل بأعلى جودة وأفضل الأسعار، من خلال التجار وأصحاب المتاجر اللذين يعرضون خدماتهم ومنتجاتهم على منصة إبل، حيث يمكنك التسوق في المتجر الذي تفضله لإبلك',
  orderNumber: 'رقم الطلب',
  orderDate: 'تاريخ الطلب',
  totalCostOfOrder: 'إجمالي تكلفة الطلب',
  rs: 'ر.س',
  storeName: 'اسم المتجر',
  productName: 'اسم المنتج',
  showProduct: 'عرض المنتج',
  printReceipt: 'طباعة الايصال'
}
