/*eslint-disable*/
export default {
  headerTitle: "قم بطلب طبيب للإبل الخاصة بك",
  headerSubtitle: "نوفر لك أطباء معتمدين لراحة الإبل الخاصة بك",
  headerBtn: "احجز موعد",
  headerDetails:
    "أطلقت منصة إبل مبادرة جديدة لعلاج الإبل والإهتمام بسلامتها من خلال إطلاق منصة بيطرة داخل موقع\n" +
    "إبل، حيث تمكنك المنصة من حرية الإختيار من مجموعة من الأطباء المعتمدين اللذين يعرضون خدماتهم\n" +
    "الطبية على منصة إبل، سواء كنت ترغب في كشف عيادة أو زيارة منزلية أو استشارة طبية، بالإضافة إلى\n" +
    "إمكانية صرف الأدوية للإبل الخاصة بك وتصلك إلى منزلك في الوقت الذي تفضله",
  ourServices: "خدماتنا",
  veterinaryServices: "خدمات البيطرة",
  veterinaryServicesDesc:
    "بعد الانتهاء من تعبئة النموذج سيظهر لك سعر علاج الإبل وفي حال قبولك للسعر،  يتم تحويلك لنظام\n" +
    "الدفع من خلال احدى وسائل الدفع المعتمدة بالمملكة العربية السعودية ،وبعدها تصدر فاتورة علاج الإبل و\n" +
    "طريقة الحجز التي قمت بإختياره",
  bookNow: "احجز الآن",
  howToBook: "كيفية الحجز",
  howToBookDesc:
    "لضمان راحة إبلك، توفر لك منصة إبل إمكانية إختيار طريقة الحجز التى تفضلها سواء كنت تفضل كشف\n" +
    "عيادة،أو زيارة منزلية، أو استشارة طبية، وتمكنك أيضا من صرف الأدوية من خلال منصة بيطرة على\n" +
    "موقع إبل. احجز الآن",
  clinicDetection: "كشف عيادة",
  HomeVisit: "زيارة ميدانية",
  medicalConsultation: "مكالمة طبيب",
  clinic_examine: 'كشف عيادة',
  outdoor_examine: 'زيارة ميدانية',
  consulting: 'مكالمة طبيب',
  dispensingMedicines: "صرف ادوية",
  city: "المدينة",
  sort: "الترتيب",
  filter: "تصفية",
  doctor: "الطبيب",
  appointment: "الموعد",
  reservationPrice: "سعر الحجز",
  BookAnAppointment: "حجز موعد",
  reservationData: "بيانات الحجز",
  bookingConfirmed: "تم تأكيد الحجز",
  serviceType: "نوع الخدمة",
  doctorChoice: "اختيار الطبيب",
  setSpecificTime: "تحديد الموعد",
  confirmReservation: "تاكيد الحجز",
  mustSelectService: "يرجى اختيار الخدمة أولا",
  searchByName: "ابحث باسم الطبيب أو المستشفى",
  waitingRoom: "صالة انتظار مرحة",
  utilityBed: "سرير للمرافق",
  chooseReservationDate: "اختيار موعد الحجز",
  from: "من",
  to: "إلى",
  today: "اليوم",
  tomorrow: "غدا",
  book: "احجز",
  modifyDay: "تعديل اليوم",
  address: "العنوان",
  rs: "ر.س",
  receipt: "الإيصال",
  bookings: "الحجوزات",
  commingSoonService: "هذذه الخدمة غير متاحة الآن",
  NumberOfCamels: "عدد المتون",
};
