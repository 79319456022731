export default {
  headerTitle: 'أول منصة لإستخراج التصاريح في المملكة',
  headerSubtitle: 'سيمكنك الحصول على التصاريح الخاصة بالإبل بسهولة من خلال منصتنا',
  extractNow: 'أستخرج الان',
  headerDetails: 'قريبا ستسهل لك منصة إبل الحصول على تصاريح الإبل الخاصة بك، نظرا لما تمثله هذه التصاريح من\n' +
    'أهمية لإمتلاك الإبل، تداولها، ونقلها ، لذلك وجب تواجد خدمة تسهل عليك استخراج التصاريح، حيث لم\n' +
    'تتأخر المملكة عن دعم الإبل وتسهيل كل الخدمات الخاصة بها بما في ذلك إستخراج التصاريح',
  whoObtainPermits: 'المستهدفين من التصاريح',
  whoObtainPermitsDesc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية حيث تستطيع الحصول على تصاريح\n' +
    'داخل المملكة وذلك من خلال اتباع الخطوات الموجودة في موقع إبل',
  camelOwnershipPermits: 'تصاريح امتلاك الإبل',
  camelOwnershipPermitsDesc: 'قريبا، سيمكنك الحصول على تصاريح إمتلاك الإبل ومشاركتها في المسابقات من خلال منصة إبل بكل\n' +
    'سهولة',
  howObtainPermits: 'كيفية إستخراج التصاريح ؟',
  howObtainPermitsDesc: 'اذا قررت إستخراج التصاريح داخل المملكة العربية السعودية، إذا عليك الإشتراك من خلال أيقونة\n' +
    'إستخرج الآن، ومن ثم قم بتعبئة النموذج مع مراعاة تعبئة جميع الطلبات في الحقول، وبذلك تكون قد\n' +
    'قدمت طلبك لإستخراج التصاريح',
  issuingCamelPermits: 'إستخراج تصاريح إبل',
  issuingCamelPermitsDesc: 'بعد الإنتهاء من تعبئة النموذج، سيظهر لك سعر التصريح، وفي حالة قبولك للسعر سيتم تحويلك لإحدى\n' +
    'أنظمة الدفع من خلال إحدى وسائل الدفع المعتمدة في المملكة العربية السعودية، وبعدها تصدر لك\n' +
    'التصاريح ويمكنك إستخدامها بكل سهولة بعد ذلك'
}
